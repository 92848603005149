import { VFC } from "react";

const CompanyGreeting: VFC = () => {
  return (
    <>
      <article>
        <p>
          私の出身はシステムエンジニアです。<br/>
          その時に店舗を経営している方々からこのようなシステムがあればいいのに、あってほしいというご相談をお受けすることが個別でありました。<br/>
          そのお話を聞くと、現状のIT業界の仕事はそのような方々に本来のITの便利さや素晴らしい部分をお伝えしきれていないのではないかと考えております。<br/>
          そこをお伝えできることを目標に当社を立ち上げました。<br/>
          この目標を達成するのに必要なことはシステムを作るエンジニアの人たちが皆様に評価いただけることであると考えております。技術者としてのプライドを持ち仕事をすることで使っていただける方に還元していく。<br/>
          双方向のコミュニケーションを大切にしていきたいと考えております。<br/>
          システムを扱う以上常に技術のプロであり、皆様のご期待に添える力を磨きながら街を様々な形で支えている皆様に還元できるような集団にしていく所存です。
        </p>
      </article>
    </>
  );
};

export default CompanyGreeting;
