import { useEffect, VFC } from "react";
import { useLocation } from "react-router-dom";
import NotFoundTemplate from "../../../templates/errors/notfound/NotFoundTemplate";
import FooterTemplate from "../../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../../templates/header/HeaderTemplate";
import TitleTemplate from "../../../templates/title/TitleTemplate";

const NotFoundPage: VFC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <HeaderTemplate/>
      <TitleTemplate title="エラー"/>
      <NotFoundTemplate/>
      <FooterTemplate/>
    </>
  );
};

export default NotFoundPage;
