import { ReactNode, VFC } from "react";

type Props = {
  children: ReactNode;
};
const ServiceDetailDescription: VFC<Props> = ({
  children,
}) => {
  return (
    <>
      {children}
    </>
  );
};

export default ServiceDetailDescription;
