import { VFC } from "react";
import classes from "./Label.module.scss";

type Props = {
  children: string;
  required?: boolean;
};

const Label: VFC<Props> = ({
  children,
  required,
}) => {
  const hasRequired = () => {
    if (required) {
      return (
        <span className={classes.required}>
          【必須】
        </span>
      );
    }

    return (
      <>
      </>
    );
  };

  return (
    <>
      <label className={classes.label}>
        {children}
        {hasRequired()}
      </label>
    </>
  );
};

export default Label;
