import { VFC } from "react";
import ContactType from "../../../types/contact/ContactType";
import Heading from "../../atoms/heading/Heading";
import ContactForm from "../../organisms/contact/form/ContactForm";
import ContactInformation from "../../organisms/contact/information/ContactInformation";
import classes from "./ContactTemplate.module.scss";

type Props = {
  onSubmit: (values: ContactType) => void;
};

const ContactTemplate: VFC<Props> = ({
  onSubmit,
}) => {
  return (
    <>
      <section className={classes.contact}>
        <Heading>お問い合わせ</Heading>
        <ContactForm
          onSubmit={onSubmit}
        />
      </section>
      <section className={classes.information}>
        <ContactInformation/>
      </section>
    </>
  );
};

export default ContactTemplate;
