import { VFC } from "react";
import classes from "./Button.module.scss";

type Props = {
  type?: "submit" | "reset" | "button" | undefined;
  children: string;
};
const Button: VFC<Props> = ({
  type,
  children,
}) => {
  return (
    <>
      <button
        type={type}
        className={classes.button}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
