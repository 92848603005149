import { useEffect, useState, VFC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import microcms from "../../../config/microcms";
import NewsType from "../../../types/news/NewsType";
import FooterTemplate from "../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../templates/header/HeaderTemplate";
import NewsTemplate from "../../templates/news/NewsTemplate";
import TitleTemplate from "../../templates/title/TitleTemplate";

const NewsPage: VFC = () => {
  const navigate = useNavigate();
  const { id } = useParams<"id">();
  const [news, setNews] = useState<NewsType>({
    id: "",
    title: "",
    body: "",
  });
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const response = await microcms.get({
          endpoint: "news",
          contentId: id,
        });
        setNews({
          id: response.id,
          title: response.title,
          body: response.body,
        });
      } catch (e) {
        navigate("/server/error");
      }
    };
    getNews();
  }, [id]);

  return (
    <>
      <HeaderTemplate/>
      <TitleTemplate title="NEWS"/>
      <NewsTemplate news={news}/>
      <FooterTemplate/>
    </>
  );
};

export default NewsPage;
