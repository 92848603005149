import { Children, ReactNode, VFC } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import SelectItem from "./item/SelectItem";
import classes from "./Select.module.scss";

type Props = {
  children: ReactNode;
  name: string;
  register: UseFormRegister<FieldValues>;
};

type SelectProps = VFC<Props> & {
  Item: typeof SelectItem;
};

const Select: SelectProps = ({
  children,
  name,
  register,
}) => {
  const items = Children.toArray(children).map(item => (<>{item}</>));
  return (
    <>
      <select
        className={classes.select}
        {...register(name)}
      >
        {items}
      </select>
    </>
  );
};

Select.Item = SelectItem;
export default Select;
