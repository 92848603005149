import { useEffect, useState, VFC } from "react";
import { useWatch } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import FormProps from "../../../molecules/forms/FormProps";
import FormTextArea from "../../../molecules/forms/textarea/FormTextArea";
import classes from "./ContactFormTextArea.module.scss";

type Props = FormProps & {
  rows?: number;
  control: Control<any>;
};

const ContactFormTextArea: VFC<Props> = ({
  label,
  name,
  register,
  control,
  required,
  error,
  rows,
}) => {
  const [count, setCount] = useState(0);
  const value = useWatch({
    control,
    name: name,
    defaultValue: "",
  });

  useEffect(() => {
    setCount(value.length);
  }, [value]);

  return (
    <>
      <section className={classes.contactFormTextArea}>
        <FormTextArea
          label={label}
          name={name}
          register={register}
          required={required}
          error={error}
          rows={rows}
        />
        <span className={classes.contactFormTextAreaCount}>{count}/500文字</span>
      </section>
    </>
  );
};

export default ContactFormTextArea;
