import { Route, Routes } from "react-router-dom";
import CompanyPage from "../components/pages/company/CompanyPage";
import ContactCompletePage from "../components/pages/contact/complete/ContactCompletePage";
import ContactPage from "../components/pages/contact/ContactPage";
import NotFoundPage from "../components/pages/errors/notfound/NotFoundPage";
import ServerErrorPage from "../components/pages/errors/server/ServerErrorPage";
import NewsSummariesPage from "../components/pages/news/list/NewsSummariesPage";
import NewsPage from "../components/pages/news/NewsPage";
import ServicePage from "../components/pages/service/ServicePage";
import TopPage from "../components/pages/top/TopPage";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TopPage/>}/>
        <Route path="/news" element={<NewsSummariesPage/>}/>
        <Route path="/news/:id" element={<NewsPage/>}/>
        <Route path="/service" element={<ServicePage/>}/>
        <Route path="/company" element={<CompanyPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/contact/complete" element={<ContactCompletePage/>}/>
        <Route path="/server/error" element={<ServerErrorPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </>
  );
};

export default Routing;
