import { useEffect, VFC } from "react";
import { useLocation } from "react-router-dom";
import SearchEngineOptimization from "../../organisms/seo/SearchEngineOptimization";
import CompanyTemplate from "../../templates/company/CompanyTemplate";
import FooterTemplate from "../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../templates/header/HeaderTemplate";
import TitleTemplate from "../../templates/title/TitleTemplate";

const CompanyPage: VFC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <SearchEngineOptimization id="company" url="/company"/>
      <HeaderTemplate/>
      <TitleTemplate title="ABOUT US"/>
      <CompanyTemplate/>
      <FooterTemplate/>
    </>
  );
};

export default CompanyPage;
