import { VFC } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import classes from "./TextArea.module.scss";

type Props = {
  name: string;
  rows?: number;
  register: UseFormRegister<FieldValues>;
};

const TextArea: VFC<Props> = ({
  name,
  rows,
  register,
}) => {
  return (
    <>
      <textarea
        className={classes.textarea}
        rows={rows}
        {...register(name)}
      />
    </>
  );
};

export default TextArea;
