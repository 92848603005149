import { VFC } from "react";
import classes from "./ContactCompleteTemplate.module.scss";

const ContactCompleteTemplate: VFC = () => {
  return (
    <>
      <section className={classes.complete}>
        <h2>3営業日以内に連絡いたします。</h2>
        <small>万が一連絡がない場合は、お手数ですが再度お問い合わせフォームよりお願いいたします。</small>
      </section>
    </>
  );
};

export default ContactCompleteTemplate;
