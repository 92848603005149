import { useEffect, useMemo, useState, VFC } from "react";
import { useLocation } from "react-router-dom";
import SearchEngineOptimization from "../../../organisms/seo/SearchEngineOptimization";
import FooterTemplate from "../../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../../templates/header/HeaderTemplate";
import NewsSummariesPaginationTemplate from "../../../templates/news/summary/NewsSummariesPaginationTemplate";
import TitleTemplate from "../../../templates/title/TitleTemplate";

const NewsSummariesPage: VFC = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [page, setPage] = useState<string | null>("1");
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMemo(() => {
    setPage(query?.get("page"));
  }, [query]);

  return (
    <>
      <SearchEngineOptimization id="news" url="/news"/>
      <HeaderTemplate/>
      <TitleTemplate title="NEWS"/>
      <NewsSummariesPaginationTemplate page={page}/>
      <FooterTemplate/>
    </>
  );
};

export default NewsSummariesPage;
