import { memo, VFC } from "react";
import Title from "../../molecules/title/Title";

type Props = {
  title: string;
};

const TitleTemplate: VFC<Props> = ({
  title,
}) => {
  return (
    <>
      <section>
        <Title>{title}</Title>
      </section>
    </>
  );
};

export default memo(TitleTemplate);
