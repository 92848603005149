import parse from "html-react-parser";
import { VFC } from "react";
import NewsType from "../../../types/news/NewsType";
import classes from "./News.module.scss";

type Props = {
  news: NewsType;
};
const News: VFC<Props> = ({
  news,
}) => {
  return (
    <>
      <article className={classes.news}>
        <h2 className={classes.newsTitle}>{news.title}</h2>
        <p>{parse(news.body)}</p>
      </article>
    </>
  );
};

export default News;
