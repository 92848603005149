import { VFC } from "react";
import classes from "./ErrorMessage.module.scss";

type Props = {
  message?: string;
};

const ErrorMessage: VFC<Props> = ({
  message,
}) => {
  if (message && message !== "") {
    return (
      <>
        <span className={classes.error}>
          {message}
        </span>
      </>
    );
  }

  return (
    <>
    </>
  );
};

export default ErrorMessage;
