import { VFC } from "react";
import Label from "../../../atoms/label/Label";
import ErrorMessage from "../../../atoms/messages/error/ErrorMessage";
import TextArea from "../../../atoms/textarea/TextArea";
import FormProps from "../FormProps";
import classes from "./FormTextArea.module.scss";

type Props = FormProps & {
  rows?: number;
};

const FormTextArea: VFC<Props> = ({
  label,
  name,
  register,
  rows,
  required,
  error,
}) => {
  return (
    <>
      <section className={classes.formTextArea}>
        <Label required={required}>
          {label}
        </Label>
        <TextArea
          name={name}
          register={register}
          rows={rows}
        />
        <ErrorMessage
          message={error}
        />
      </section>
    </>
  );
};

export default FormTextArea;
