import { VFC } from "react";
import greeting from "../../../assets/images/greeting.jpg";
import Heading from "../../atoms/heading/Heading";
import Image from "../../atoms/image/Image";
import CompanyAboutUs from "../../organisms/company/aboutus/CompanyAboutUs";
import CompanyGreeting from "../../organisms/company/greeting/CompanyGreeting";
import CompanyManagement from "../../organisms/company/management/CompanyManagement";
import CompanyRecruitment from "../../organisms/company/recruitment/CompanyRecruitment";
import classes from "./CompanyTemplate.module.scss";

const CompanyTemplate: VFC = () => {
  return (
    <>
      <section className={classes.company}>
        <Heading>会社概要</Heading>
        <CompanyAboutUs/>
      </section>
      <section className={classes.management}>
        <Heading>経営理念</Heading>
        <CompanyManagement/>
      </section>
      <section className={classes.greeting}>
        <Heading>代表挨拶</Heading>
        <div className={classes.greetingImage}>
          <Image src={greeting} alt="代表挨拶"/>
        </div>
        <CompanyGreeting/>
      </section>
      <section className={classes.recruitment}>
        <Heading>採用情報</Heading>
        <div className={classes.recruitmentContent}>
          <CompanyRecruitment/>
        </div>

        <div className={classes.recruitmentContent}>
          <a href="https://levtech-direct.jp/company/1505">
            <img
              src="https://levtech-direct.s3-ap-northeast-1.amazonaws.com/202009/logo.png"
              alt="取材記事を見る｜ITエンジニア・クリエイターの求人・転職ならレバテックダイレクト"
              className={classes.recruitmentContentLevtech}
            />
          </a>
        </div>
      </section>
    </>
  );
};

export default CompanyTemplate;
