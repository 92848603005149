import { Children, ReactNode, VFC } from "react";
import Label from "../../../atoms/label/Label";
import ErrorMessage from "../../../atoms/messages/error/ErrorMessage";
import SelectItem from "../../../atoms/select/item/SelectItem";
import Select from "../../../atoms/select/Select";
import FormProps from "../FormProps";
import classes from "./FormSelect.module.scss";

const FormSelectItem = SelectItem;

type Props = FormProps & {
  children: ReactNode;
};

type FormSelectProps = VFC<Props> & {
  Item: typeof FormSelectItem;
};

const FormSelect: FormSelectProps = ({
  label,
  name,
  register,
  children,
  required,
  error,
}) => {
  const renderSelectItem = () => {
    return Children.toArray(children).map(child => (
      <>{child}</>
    ));
  };

  return (
    <>
      <section className={classes.formSelect}>
        <Label
          required={required}
        >
          {label}
        </Label>
        <Select
          name={name}
          register={register}
        >
          {renderSelectItem()}
        </Select>
        <ErrorMessage
          message={error}
        />
      </section>
    </>
  );
};

FormSelect.Item = FormSelectItem;
export default FormSelect;
