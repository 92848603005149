import { Children, ReactNode, VFC } from "react";
import { useNavigate } from "react-router-dom";
import marespera_text from "../../../../assets/images/marespera_text.png";
import Image from "../../../atoms/image/Image";
import NavigationLargeItem from "./item/NavigationLargeItem";
import classes from "./NavigationLarge.module.scss";

type Props = {
  children: ReactNode;
};

type NavigationLargeProps = VFC<Props> & {
  Item: typeof NavigationLargeItem;
};

const NavigationLarge: NavigationLargeProps = ({
  children,
  ...other
}) => {
  const navigate = useNavigate();
  const navigations = Children.toArray(children).map(item => (
    <>
      {item}
    </>
  ));

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <nav
        className={classes.navigation}
        {...other}
      >
        <ul
          className={classes.navigationList}
        >
          <li>
            <section
              className={classes.navigationLogo}
              onClick={handleLogoClick}
            >
              <Image
                src={marespera_text}
                alt="MareSpera（マレスペラ）"
              />
            </section>
          </li>
          {navigations}
        </ul>
      </nav>
    </>
  );
};

NavigationLarge.Item = NavigationLargeItem;
export default NavigationLarge;
