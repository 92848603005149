import { HTMLInputTypeAttribute, VFC } from "react";
import Input from "../../../atoms/input/Input";
import Label from "../../../atoms/label/Label";
import ErrorMessage from "../../../atoms/messages/error/ErrorMessage";
import FormProps from "../FormProps";
import classes from "./FormInput.module.scss";

type Props = FormProps & {
  type: HTMLInputTypeAttribute;
};

const FormInput: VFC<Props> = ({
  type,
  label,
  name,
  register,
  required,
  error,
}) => {
  return (
    <>
      <section className={classes.formInput}>
        <Label required={required}>
          {label}
        </Label>
        <Input
          type={type}
          name={name}
          register={register}
        />
        <ErrorMessage
          message={error}
        />
      </section>
    </>
  );
};

export default FormInput;
