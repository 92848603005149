import { memo, useMemo, useState, VFC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./ViewMore.module.scss";

type Props = {
  pathname: string,
};

const ViewMore: VFC<Props> = ({
  pathname,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [page, setPage] = useState<string | null>("1");

  useMemo(() => {
    setPage(query?.get("page"));
  }, [query]);

  const handleClick = () => {
    navigate(`/${pathname}?page=${Number(page) + 1}`);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={classes.viewmore}
      >
        もっと見る
      </button>
    </>
  );
};

export default memo(ViewMore);
