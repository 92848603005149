import { VFC } from "react";
import classes from "./CompanyAboutUs.module.scss";

const CompanyAboutUs: VFC = () => {
  return (
    <>
      <section className={classes.aboutUs}>
        <ul>
          <li className={classes.right}>社名：</li>
          <li>株式会社MareSpera</li>
        </ul>
        <ul>
          <li className={classes.right}>本社：</li>
          <li>東京都立川市曙町2-14-23 東財曙町第二ビル 2F</li>
        </ul>
        <ul>
          <li className={classes.right}>代表取締役：</li>
          <li>笠原&nbsp;&nbsp;拓</li>
        </ul>
        <ul>
          <li className={classes.right}>設立：</li>
          <li>2018年1月</li>
        </ul>
        <ul>
          <li className={classes.right}>資本金：</li>
          <li>600万円</li>
        </ul>
        <ul>
          <li className={classes.right}>事業内容：</li>
          <li>予約・記録システム開発及びシステムソリューション（受託開発）</li>
        </ul>
        <ul>
          <li className={classes.right}>主要取引銀行：</li>
          <li>みずほ銀行</li>
        </ul>
      </section>
    </>
  );
};

export default CompanyAboutUs;
