import { useEffect, VFC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchEngineOptimization from "../../organisms/seo/SearchEngineOptimization";
import FooterTemplate from "../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../templates/header/HeaderTemplate";
import ServiceTemplate from "../../templates/service/ServiceTemplate";
import TitleTemplate from "../../templates/title/TitleTemplate";

const ServicePage: VFC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleClickContact = () => {
    navigate("/contact");
  };

  return (
    <>
      <SearchEngineOptimization id="service" url="/service"/>
      <HeaderTemplate/>
      <TitleTemplate title="事業内容"/>
      <ServiceTemplate
        onClickContact={handleClickContact}
      />
      <FooterTemplate/>
    </>
  );
};

export default ServicePage;
