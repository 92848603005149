import { VFC } from "react";

const CompanyManagement: VFC = () => {
  return (
    <>
      <article>
        <p>
          技術者集団として専門技術を持った人が正しく評価され、今よりもよりよいものを出せるように努力をし、常に最新のトレンドを追いかけ、広い目線と確かな技術、信頼を勝ち取り、自分たちを含めたシステムに関わる人全てがプラスになるように貢献をする。
        </p>
      </article>
    </>
  );
};

export default CompanyManagement;
