import { useEffect, useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import microcms from "../../../../config/microcms";
import NewsType from "../../../../types/news/NewsType";
import NewsSummary from "../../../molecules/news/summary/NewsSummary";

const renderNewsSummaries = (news: NewsType[]) => (
  news.map(news => (
    <>
      <NewsSummary
        id={news.id}
        title={news.title}
        summary={news.body}
      />
    </>
  ))
);

type Props = {
  page?: string | null;
  limit: number;
};

const NewsSummaries: VFC<Props> = ({
  page,
  limit,
}) => {
  const [news, setNews] = useState<NewsType[]>([]);
  const navigate = useNavigate();

  const addNews = (targets: NewsType[]) => {
    setNews(news.concat(targets));
  };

  useEffect(() => {
    let offset = 0;
    if (page !== null && page !== "" && !isNaN(Number(page))) {
      if (Number(page) > 1) {
        offset = (Number(page) - 1) * limit;
      }
    }
    const getNews = async () => {
      try {
        const response = await microcms.get({
          endpoint: "news",
          queries: {
            limit: limit,
            offset: offset,
          },
        });
        addNews(response.contents.map((content: NewsType) => ({
          id: content.id,
          title: content.title,
          body: content.body,
        })));
      } catch (e) {
        navigate("/server/error");
      }
    };

    getNews();
  }, [page]);

  return (
    <>
      {renderNewsSummaries(news)}
    </>
  );
};

export default NewsSummaries;
