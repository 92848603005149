import { VFC } from "react";
import Icon from "../icon/Icon";

const Copyright: VFC = () => {
  const date: Date = new Date();

  return (
    <>
      <small>
        Copyright&nbsp;&nbsp;
        <Icon icon="copyright"/>
        &nbsp;&nbsp;{date.getFullYear()} 株式会社MareSpera
      </small>
    </>
  );
};

export default Copyright;
