import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { VFC } from "react";

type Props = FontAwesomeIconProps;

const Icon: VFC<Props> = (props) => {
  return (
    <>
      <FontAwesomeIcon {...props}/>
    </>
  );
};

export default Icon;
