import { useEffect, VFC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContactType from "../../../types/contact/ContactType";
import ContactTemplate from "../../templates/contact/ContactTemplate";
import FooterTemplate from "../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../templates/header/HeaderTemplate";
import TitleTemplate from "../../templates/title/TitleTemplate";

const ContactPage: VFC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSubmit = (values: ContactType) => {
    let formData = new URLSearchParams();
    formData.append("oid", `${process.env.REACT_APP_SALESFORCE_ORGANIZATION_ID}`);
    formData.append("company", values.company);
    formData.append("email", values.email);
    formData.append("last_name", values.lastName);
    formData.append("first_name", values.firstName);
    formData.append("phone", values.phone);
    formData.append("00N7F00000EuKu2", values.intention);
    formData.append("00N7F00000EuNYF", values.contact);
    formData.append("lead_source", "お問い合わせ");

    const url = `${process.env.REACT_APP_SALESFORCE_WEB_TO_LEAD}`;

    fetch(url, {
      method: "POST",
      mode: "no-cors",
      body: formData,
    }).then(() => navigate("/contact/complete"))
      .catch(() => navigate("/server/error"));
  };

  return (
    <>
      <HeaderTemplate/>
      <TitleTemplate title="CONTACT"/>
      <ContactTemplate onSubmit={handleSubmit}/>
      <FooterTemplate/>
    </>
  );
};

export default ContactPage;
