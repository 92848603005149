import { VFC } from "react";
import classes from "./ServerErrorTemplate.module.scss";

const ServerErrorTemplate: VFC = () => {
  return (
    <>
      <section className={classes.server}>
        <h2>エラーが発生しました</h2>
        <small>お手数ですが、時間をおいて再度お試しください。</small>
      </section>
    </>
  );
};

export default ServerErrorTemplate;
