import { VFC } from "react";
import Copyright from "../../atoms/copyright/Copyright";
import Icon from "../../atoms/icon/Icon";
import classes from "./FooterTemplate.module.scss";

const FooterTemplate: VFC = () => {
  return (
    <>
      <footer className={classes.footer}>
        <ul className={classes.footerSocial}>
          <li>
            <a href="https://twitter.com/info_marespera" target="_blank" rel="noreferrer nofollow">
              <Icon icon="twitter" size="2x" color="white"/>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/infomarespera/" target="_blank" rel="noreferrer nofollow">
              <Icon icon="facebook" size="2x" color="white"/>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/maresperacoltd/" target="_blank" rel="noreferrer nofollow">
              <Icon icon="instagram" size="2x" color="white"/>
            </a>
          </li>
        </ul>
        <section className={classes.footerCopyright}>
          <Copyright/>
        </section>
      </footer>
    </>
  );
};

export default FooterTemplate;
