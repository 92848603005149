import { VFC } from "react";
import classes from "./ServiceTitle.module.scss";

type Props = {
  children: string;
  no: string;
};

const ServiceTitle: VFC<Props> = ({
  children,
  no,
}) => {
  return (
    <>
      <h1 className={classes.service}>
        <span className={classes.serviceNumber}>
          {no}
        </span>
        <span className={classes.serviceTitle}>
          <span className={classes.serviceTitleBorder}/>
          <span className={classes.serviceTitleChildren}>{children}</span>
        </span>
      </h1>
    </>
  );
};

export default ServiceTitle;
