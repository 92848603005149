import { ReactNode, VFC } from "react";
import classes from "./Heading.module.scss";

type Props = {
  children: ReactNode;
};
const Heading: VFC<Props> = ({
  children,
}) => {
  return (
    <>
      <h1 className={classes.heading}>
        {children}
      </h1>
    </>
  );
};

export default Heading;
