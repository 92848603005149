import { Children, isValidElement, ReactNode, VFC } from "react";
import ServiceDetailDescription from "./description/ServiceDetailDescription";
import classes from "./ServiceDetail.module.scss";
import ServiceDetailTopic from "./topic/ServiceDetailTopic";

type Props = {
  children: ReactNode;
};

type ServiceDetailProps = VFC<Props> & {
  Topic: typeof ServiceDetailTopic;
  Description: typeof ServiceDetailDescription;
};

const ServiceDetail: ServiceDetailProps = ({
  children,
}) => {
  const topic = Children.toArray(children).filter(child => {
    return isValidElement(child) ? child.type === ServiceDetailTopic : false;
  });

  const content = Children.toArray(children).filter(child => {
    return isValidElement(child) ? child.type === ServiceDetailDescription : false;
  });

  return (
    <>
      <section className={classes.serviceDetail}>
        {topic}
        {content}
      </section>
    </>
  );
};

ServiceDetail.Topic = ServiceDetailTopic;
ServiceDetail.Description = ServiceDetailDescription;

export default ServiceDetail;
