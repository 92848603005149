import { VFC } from "react";
import logo from "../../../../assets/images/logo.png";
import recruitment from "../../../../assets/images/recruitment.png";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import classes from "./CompanyRecruitment.module.scss";

const CompanyRecruitment: VFC = () => {
  return (
    <>
      <a
        href="https://en-gage.net/marespera_recruit/?banner=1"
        className={classes.recruitment}
        target="_blank"
        rel="noopener noreferer"
      >
        <article className={classes.recruitment}>
          <div className={classes.recruitmentHeader}>
            採用特設ページ公開中！
            <div className={classes.recruitmentHeaderEngage}>
              <Image
                src="https://en-gage.net/imageFile_new/company/logo_white.svg"
                alt="engage"
                height="14"
                width="40"
              />
            </div>
          </div>
          <div>
            <Image
              src={recruitment}
              alt="採用情報"
              height="145"
              width="500"
              className={classes.recruitmentHeaderImage}
            />
            <div className={classes.recruitmentHeaderArea}>
              <Image
                src={logo}
                alt="ロゴ"
                height="30"
                width="30"
                className={classes.recruitmentHeaderAreaLogo}
              />
              <p>株式会社ＭａｒｅＳｐｅｒａ</p>
            </div>
            <div className={classes.recruitmentCatch}>
              careers&nbsp;at&nbsp;株式会社ＭａｒｅＳｐｅｒａ&nbsp;私たちと一緒に働きませんか？
            </div>
            <div className={classes.recruitmentFooter}>
              <button>
                今すぐチェック！<Icon icon="angle-right" size="1x" color="#E6870C"/>
              </button>
            </div>
          </div>
        </article>
      </a>
    </>
  );
};

export default CompanyRecruitment;
