import { VFC } from "react";
import classes from "./NotFoundTemplate.module.scss";

const NotFoundTemplate: VFC = () => {
  return (
    <>
      <section className={classes.notfound}>
        <h2>404 お探しのページはありません</h2>
      </section>
    </>
  );
};

export default NotFoundTemplate;
