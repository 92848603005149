import { useEffect, VFC } from "react";
import { useLocation } from "react-router-dom";
import SearchEngineOptimization from "../../organisms/seo/SearchEngineOptimization";
import FooterTemplate from "../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../templates/header/HeaderTemplate";
import HeroTemplate from "../../templates/hero/HeroTemplate";
import NewsSummariesTemplate from "../../templates/news/summary/NewsSummariesTemplate";

const TopPage: VFC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <SearchEngineOptimization id="top" url=""/>
      <HeaderTemplate/>
      <HeroTemplate/>
      <NewsSummariesTemplate limit={3}/>
      <FooterTemplate/>
    </>
  );
};

export default TopPage;
