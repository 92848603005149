import { VFC } from "react";
import NewsType from "../../../types/news/NewsType";
import Heading from "../../atoms/heading/Heading";
import News from "../../organisms/news/News";
import classes from "./NewsTemplate.module.scss";

type Props = {
  news: NewsType;
};

const NewsTemplate: VFC<Props> = ({
  news,
}) => {
  return (
    <>
      <section className={classes.news}>
        <Heading>ニュース</Heading>
        <News news={news}/>
      </section>
    </>
  );
};

export default NewsTemplate;
