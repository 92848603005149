import { ReactNode, VFC } from "react";
import classes from "./ServiceDetailTopic.module.scss";

type Props = {
  children: ReactNode;
};

const ServiceDetailTopic: VFC<Props> = ({
  children,
}) => {
  return (
    <>
      <h3 className={classes.topic}>{children}</h3>
    </>
  );
};

export default ServiceDetailTopic;
