import { yupResolver } from "@hookform/resolvers/yup";
import { VFC } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ContactType from "../../../../types/contact/ContactType";
import Button from "../../../atoms/button/Button";
import FormInput from "../../../molecules/forms/input/FormInput";
import FormSelect from "../../../molecules/forms/select/FormSelect";
import ContactFormTextArea from "../textarea/ContactFormTextArea";

const schema = yup.object({
  company: yup.string()
    .required("法人名を入力してください"),
  email: yup.string()
    .required("ご担当者さまメールアドレスを入力してください")
    .email("ご担当者さまメールアドレスはメールアドレス形式で入力してください"),
  lastName: yup.string()
    .required("ご担当者さま(姓)を入力してください"),
  firstName: yup.string()
    .required("ご担当者さま(名)を入力してください"),
  phone: yup.string()
    .required("電話番号を入力してください"),
  intention: yup.string()
    .required("導入意思を選択してください"),
  contact: yup.string()
    .max(500, "お問い合わせ内容は500文字以内で入力してください")
    .required("お問い合わせ内容を入力してください"),
});

type Props = {
  onSubmit: (values: ContactType) => void;
};

const ContactForm: VFC<Props> = ({
  onSubmit,
}) => {
  const { register, handleSubmit, control, formState: { errors } } = useForm<ContactType>({
    resolver: yupResolver(schema),
  });
  return (
    <>
      <form
        onSubmit={handleSubmit(values => onSubmit(values))}
      >
        <FormInput
          label="法人名"
          name="company"
          register={register}
          type="text"
          required
          error={errors.company?.message}
        />
        <FormInput
          label="ご担当者さまメールアドレス"
          name="email"
          register={register}
          type="text"
          required
          error={errors.email?.message}
        />
        <FormInput
          label="ご担当者さま(姓)"
          name="lastName"
          register={register}
          type="text"
          required
          error={errors.lastName?.message}
        />
        <FormInput
          label="ご担当者さま(名)"
          name="firstName"
          register={register}
          type="text"
          required
          error={errors.firstName?.message}
        />
        <FormInput
          label="電話番号"
          name="phone"
          register={register}
          type="text"
          required
          error={errors.phone?.message}
        />
        <FormSelect
          label="導入意思"
          name="intention"
          register={register}
          required
          error={errors.intention?.message}
        >
          <FormSelect.Item value="興味がある">興味がある</FormSelect.Item>
          <FormSelect.Item value="今すぐ導入">今すぐ導入</FormSelect.Item>
          <FormSelect.Item value="導入検討中">導入検討中</FormSelect.Item>
        </FormSelect>
        <ContactFormTextArea
          label="お問い合わせ内容"
          name="contact"
          register={register}
          control={control}
          required
          error={errors.contact?.message}
          rows={5}
        />
        <Button type="submit">送信</Button>
      </form>
    </>
  );
};

export default ContactForm;
