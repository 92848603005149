import { VFC } from "react";
import Hero from "../../molecules/hero/Hero";
import classes from "./HeroTemplate.module.scss";

const HeroTemplate: VFC = () => {
  return (
    <>
      <section className={classes.hero}>
        <Hero/>
      </section>
    </>
  );
};

export default HeroTemplate;
