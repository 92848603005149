import { VFC } from "react";
import Image from "../../atoms/image/Image";
import classes from "./Hero.module.scss";
import hero from "../../../assets/images/hero.png";

const Hero: VFC = () => {
  return (
    <>
      <section className={classes.hero}>
        <Image
          src={hero}
          alt="関わる人すべてがプラスに"
          className={classes.heroImage}
        />
        <div className={classes.heroBackground}/>
        <div className={classes.heroContent}>
          <h2>関わる人すべてが<span>プラスに</span></h2>
          <p>株式会社MareSpera（マレスペラ）</p>
        </div>
      </section>
    </>
  );
};

export default Hero;
