import { VFC } from "react";
import Heading from "../../../atoms/heading/Heading";
import NewsSummaries from "../../../organisms/news/summary/NewsSummaries";
import classes from "./NewsSummariesTemplate.module.scss";

type Props = {
  limit: number;
};

const NewsSummariesTemplate: VFC<Props> = ({
  limit,
}) => {
  return (
    <>
      <section className={classes.news}>
        <Heading>ニュース</Heading>
        <NewsSummaries limit={limit}/>
      </section>
    </>
  );
};

export default NewsSummariesTemplate;
