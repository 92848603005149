import { VFC } from "react";
import classes from "./ContactInformation.module.scss";

const ContactInformation: VFC = () => {
  return (
    <>
      <small className={classes.information}>
        なお、営業問い合わせについては弊社業務に関係ないものと判断した場合、 ご返信をいたしませんので、予めご了承ください。
      </small>
    </>
  );
};

export default ContactInformation;
