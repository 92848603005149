import { VFC } from "react";

type Props = {
  children: string;
  value: string;
};

const SelectItem: VFC<Props> = ({
  children,
  value,
}) => {
  return (
    <>
      <option value={value}>{children}</option>
    </>
  );
};

export default SelectItem;
