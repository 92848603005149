import { Children, ReactNode, useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import marespera_text from "../../../../assets/images/marespera_text.png";
import Image from "../../../atoms/image/Image";
import NavigationSmallItem from "./item/NavigationSmallItem";
import classes from "./NavigationSmall.module.scss";

type Props = {
  children: ReactNode;
};

type NavigationSmallProps = VFC<Props> & {
  Item: typeof NavigationSmallItem;
};

const NavigationSmall: NavigationSmallProps = ({
  children,
  ...other
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const navigations = Children.toArray(children).map(item => (
    <>
      {item}
    </>
  ));

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <nav
        className={classes.navigation}
      >
        <section
          className={classes.navigationLogo}
          onClick={handleLogoClick}
        >
          <Image
            src={marespera_text}
            alt="MareSpera（マレスペラ）"
          />
        </section>
        <button
          className={classes.navigationToggle}
          onClick={handleClick}
        >
          <span
            className={open ? classes.navigationToggleOpen : classes.navigationToggleClose}
          />
        </button>

        <ul
          className={`${classes.navigationList} ${open ? classes.navigationOpen : classes.navigationClose}`}
          {...other}
        >
          {navigations}
        </ul>
      </nav>
    </>
  );
};

NavigationSmall.Item = NavigationSmallItem;
export default NavigationSmall;
