import { HTMLInputTypeAttribute, VFC } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import classes from "./Input.module.scss";

type Props = {
  type: HTMLInputTypeAttribute;
  name: string;
  register: UseFormRegister<FieldValues>;
};

const Input: VFC<Props> = ({
  type,
  name,
  register,
}) => {
  return (
    <>
      <input
        type={type}
        className={classes.input}
        {...register(name)}
      />
    </>
  );
};

export default Input;
