import { useEffect, useState, VFC } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import microcms from "../../../config/microcms";
import SeoType from "../../../types/seo/SeoType";

type Props = {
  id: string;
  url: string;
};

const SearchEngineOptimization: VFC<Props> = ({
  id,
  url,
}) => {
  const navigate = useNavigate();
  const [seo, setSeo] = useState<SeoType>({
    title: "",
    description: "",
    site_name: "",
    image: {
      url: "",
      height: 0,
      width: 0,
    },
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    const getSeo = async () => {
      try {
        const response = await microcms.get({
          endpoint: "seo",
          contentId: id,
        });
        setSeo(response);
      } catch (e) {
        navigate("/server/error");
      }
    };

    getSeo();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description}/>
        <meta name="robots" content="max-image-preview:large"/>
        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:site_name" content={seo.site_name}/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content={seo.title}/>
        <meta property="og:description" content={seo.description}/>
        <meta property="og:url" content={`${process.env.REACT_APP_SITE_BASE}${url}`}/>
        <meta property="og:image" content={seo.image.url}/>
        <meta property="og:image:secure_url" content={seo.image.url}/>
        <meta property="article:published_time" content={seo.createdAt}/>
        <meta property="article:modified_time" content={seo.updatedAt}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={seo.title}/>
        <meta name="twitter:description" content={seo.description}/>
        <meta name="twitter:image" content={seo.image.url}/>
      </Helmet>
    </>
  );
};

export default SearchEngineOptimization;
