import { ReactNode, VFC } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationLargeItem.module.scss";

type Props = {
  to: string;
  outer?: boolean;
  children: ReactNode;
};

const NavigationLargeItem: VFC<Props> = ({
  to,
  outer,
  children,
  ...other
}) => {
  if (outer) {
    return (
      <li
        className={classes.navigation}
        {...other}
      >
        <a
          href={to}
          className={classes.navigationItem}
        >
          {children}
        </a>
      </li>
    );
  }

  return (
    <li
      className={classes.navigation}
      {...other}
    >
      <NavLink
        to={to}
        className={classes.navigationItem}
      >
        {children}
      </NavLink>
    </li>
  );
};

export default NavigationLargeItem;
