import { useEffect, VFC } from "react";
import { useLocation } from "react-router-dom";
import ServerErrorTemplate from "../../../templates/errors/server/ServerErrorTemplate";
import FooterTemplate from "../../../templates/footer/FooterTemplate";
import HeaderTemplate from "../../../templates/header/HeaderTemplate";
import TitleTemplate from "../../../templates/title/TitleTemplate";

const ServerErrorPage: VFC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <HeaderTemplate/>
      <TitleTemplate title="エラー"/>
      <ServerErrorTemplate/>
      <FooterTemplate/>
    </>
  );
};

export default ServerErrorPage;
