import { Children, isValidElement, ReactNode, VFC } from "react";
import Image from "../../atoms/image/Image";
import ServiceDetail from "./detail/ServiceDetail";
import ServiceTitle from "./title/ServiceTitle";
import classes from "./Service.module.scss";

type Props = {
  children: ReactNode;
};

type ServiceProps = VFC<Props> & {
  Title: typeof ServiceTitle;
  Image: typeof Image;
  Detail: typeof ServiceDetail;
};

const Service: ServiceProps = ({
  children,
}) => {
  const title = Children.toArray(children).filter(child => {
    return isValidElement(child) ? child.type === ServiceTitle : false;
  });
  const detail = Children.toArray(children).filter(child => {
    return isValidElement(child) ? child.type === ServiceDetail : false;
  });
  const image = Children.toArray(children).filter(child => {
    return isValidElement(child) ? child.type === Image : false;
  });

  return (
    <>
      <article className={classes.service}>
        {title}
        <div className={classes.serviceDetail}>
          <section className={classes.serviceDetailImage}>
            {image}
          </section>
          <section className={classes.serviceDetailDescription}>
            {detail}
          </section>
        </div>
      </article>
    </>
  );
};

Service.Title = ServiceTitle;
Service.Image = Image;
Service.Detail = ServiceDetail;

export default Service;
