import { VFC } from "react";
import service_engineering from "../../../assets/images/service_engineering.jpg";
import service_reska from "../../../assets/images/service_reska.png";
import service_system_solution from "../../../assets/images/service_system_solution.jpg";
import Image from "../../atoms/image/Image";
import Service from "../../organisms/service/Service";
import classes from "./ServiceTemplate.module.scss";
import more from "../../../assets/images/more.png";
import contact from "../../../assets/images/contact.png";

type Props = {
  onClickContact: () => void;
};

const ServiceTemplate: VFC<Props> = ({
  onClickContact,
}) => {
  return (
    <>
      <section className={classes.service}>
        <Service>
          <Service.Title no="01.">
            予約・記録システム&nbsp;&nbsp;ResKa&nbsp;&nbsp;の提供
          </Service.Title>
          <Service.Image
            src={service_reska}
            alt="予約・記録システム ResKa"
            height="200"
            width="300"
          />
          <Service.Detail>
            <Service.Detail.Topic>
              WEB予約・記録サービスのResKaをご提供しています。
            </Service.Detail.Topic>
            <Service.Detail.Description>
              <p>
                予約業務と顧客対応記録をワンストップで管理するWEBサービスResKaを提供しております。以下のボタンより、サービスの詳細をご確認いただけます。
              </p>
              <a
                href="https://reska.marespera.co.jp"
                target="_blank"
                rel="noreferrer noopener"
                className={classes.button}
              >
                <Image
                  src={more}
                  alt="詳しくはこちら"
                  height="40"
                />
              </a>
            </Service.Detail.Description>
          </Service.Detail>
        </Service>

        <Service>
          <Service.Title no="02.">
            システムソリューション（受託開発）
          </Service.Title>
          <Service.Image
            src={service_system_solution}
            alt="システムソリューション（受託開発）"
            height="200"
            width="300"
          />
          <Service.Detail>
            <Service.Detail.Topic>
              お客様が抱えられている課題をシステム開発にて解決いたします。
            </Service.Detail.Topic>
            <Service.Detail.Description>
              <p>
                お客様が抱える課題をヒアリングさせていただき、システムの提案から構築まで対応いたします。お気軽にご相談ください。
              </p>
              <Image
                src={contact}
                alt="お問い合わせはこちら"
                height="40"
                className={classes.button}
                onClick={onClickContact}
              />
            </Service.Detail.Description>
          </Service.Detail>
        </Service>

        <Service>
          <Service.Title no="03.">
            エンジニアリングサービス
          </Service.Title>
          <Service.Image
            src={service_engineering}
            alt="エンジニアリングサービス"
            height="200"
            width="300"
          />
          <Service.Detail>
            <Service.Detail.Topic>
              システムに関する業務に対してエンジニアをご提供いたします。
            </Service.Detail.Topic>
            <Service.Detail.Description>
              <p>
                システム開発・保守・運用などの業務に対して、エンジニアの技術力および労働力をご提供いたします。労務管理や指揮命令系統は当社が行いますので、お客様の管理負担を軽減することができます。
              </p>
              <Image
                src={contact}
                alt="お問い合わせはこちら"
                height="40"
                className={classes.button}
                onClick={onClickContact}
              />
            </Service.Detail.Description>
          </Service.Detail>
        </Service>
      </section>
    </>
  );
};

export default ServiceTemplate;
