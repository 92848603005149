import { VFC } from "react";
import title_background from "../../../assets/images/title_background.png";
import Image from "../../atoms/image/Image";
import classes from "./Title.module.scss";

type Props = {
  children: string;
};

const Title: VFC<Props> = ({
  children,
}) => {
  return (
    <>
      <section className={classes.title}>
        <Image
          src={title_background}
          alt={children}
          width="100%"
          height="80%"
        />
        <div className={classes.titleContent}>
          <h1>{children}</h1>
        </div>
      </section>
    </>
  );
};

export default Title;
