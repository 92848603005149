import { memo, VFC } from "react";
import NavigationLarge from "../../organisms/navigation/large/NavigationLarge";
import NavigationSmall from "../../organisms/navigation/small/NavigationSmall";
import classes from "./HeaderTemplate.module.scss";

const HeaderTemplate: VFC = () => {
  return (
    <>
      <header className={classes.header}>
        <section className={classes.headerLarge}>
          <NavigationLarge>
            <NavigationLarge.Item to="/">トップ</NavigationLarge.Item>
            <NavigationLarge.Item to="/news?page=1">ニュース</NavigationLarge.Item>
            <NavigationLarge.Item to="/service">サービス</NavigationLarge.Item>
            <NavigationLarge.Item to="/company">会社概要</NavigationLarge.Item>
            <NavigationLarge.Item to="/contact">お問い合わせ</NavigationLarge.Item>
            <NavigationLarge.Item to="https://blog.marespera.co.jp" outer>ブログ</NavigationLarge.Item>
          </NavigationLarge>
        </section>

        <section className={classes.headerSmall}>
          <NavigationSmall>
            <NavigationSmall.Item to="/">トップ</NavigationSmall.Item>
            <NavigationSmall.Item to="/news?page=1">ニュース</NavigationSmall.Item>
            <NavigationSmall.Item to="/service">サービス</NavigationSmall.Item>
            <NavigationSmall.Item to="/company">会社概要</NavigationSmall.Item>
            <NavigationSmall.Item to="/contact">お問い合わせ</NavigationSmall.Item>
            <NavigationSmall.Item to="https://blog.marespera.co.jp" outer>ブログ</NavigationSmall.Item>
          </NavigationSmall>
        </section>
      </header>
    </>
  );
};

export default memo(HeaderTemplate);
