import parse from "html-react-parser";
import { VFC } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./NewsSummary.module.scss";


type Props = {
  id: string;
  title: string;
  summary: string;
};

const NewsSummary: VFC<Props> = ({
  id,
  title,
  summary,
}) => {
  const navigate = useNavigate();

  const summaryText = () => {
    if (summary.length >= 100) {
      return summary.slice(0, 99).concat("...");
    }
    return summary;
  };

  const handleClick = () => {
    navigate(`/news/${id}`);
  };

  return (
    <>
      <article className={classes.news} onClick={handleClick}>
        <h2 className={classes.newsTitle}>{title}</h2>
        <p>{parse(summaryText())}</p>
      </article>
    </>
  );
};

export default NewsSummary;
