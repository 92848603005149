import { VFC } from "react";
import Heading from "../../../atoms/heading/Heading";
import NewsSummaries from "../../../organisms/news/summary/NewsSummaries";
import ViewMore from "../../../organisms/pagination/ViewMore";
import classes from "./NewsSummariesPaginationTemplate.module.scss";

type Props = {
  page: string | null;
};

const NewsSummariesPaginationTemplate: VFC<Props> = ({
  page,
}) => {
  return (
    <>
      <section className={classes.news}>
        <Heading>ニュース</Heading>
        <NewsSummaries page={page} limit={10}/>
        <div className={classes.newsViewMore}>
          <ViewMore pathname="news"/>
        </div>
      </section>
    </>
  );
};

export default NewsSummariesPaginationTemplate;
