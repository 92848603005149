import { ImgHTMLAttributes, VFC } from "react";

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
};

const Image: VFC<Props> = ({
  src,
  alt,
  ...other
}) => {
  return (
    <>
      <img
        src={src}
        alt={alt}
        {...other}
      />
    </>
  );
};

export default Image;
